import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Typography, Spin,Badge, Space } from 'antd';
import axios from 'axios';
import './App.css'; // 确保引入了CSS文件

const App: React.FC = () => {
  const [hitokoto, setHitokoto] = useState({ hitokoto: '', from: '', from_who: '', type: '',color:'',uuid:'',creator:''});
  const [loading, setLoading] = useState(true);
  const [showHitokoto, setShowHitokoto] = useState(hitokoto); // 新增状态用于动画切换

  useEffect(() => {
    const fetchHitokoto = async () => {
      try {
        const response = await axios.get('https://international.v1.hitokoto.cn?&encode=json');
        const hitokotoType: { [key: string]: string } = {
          'a': '动画',
          'b': '漫画',
          'c': '游戏',
          'd': '小说',
          'e': '原创',
          'f': '网络',
          'g': '其他',
          'h': '影视',
          'i': '诗词',
          'j': '网易云',
          'k': '哲学',
          'l': '抖机灵'
        };

        const colors = [
          'pink',
          'red',
          'yellow',
          'orange',
          'cyan',
          'green',
          'blue',
          'purple',
          'geekblue',
          'magenta',
          'volcano',
          'gold',
          'lime',
        ];

        setHitokoto({
          hitokoto: response.data.hitokoto,
          from: response.data.from,
          from_who: response.data.from_who,
          type: hitokotoType[response.data.type],
          color: colors[Math.floor(Math.random() * colors.length)],
          uuid: response.data.uuid,
          creator: response.data.creator
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching hitokoto:', error);
        setLoading(false);
      }
    };

    const intervalId = setInterval(fetchHitokoto, 5000);

    fetchHitokoto();

    return () => clearInterval(intervalId);
  }, []);

  // 通过useEffect监听hitokoto状态的变化，实现动画效果
  useEffect(() => {
    setShowHitokoto(hitokoto);
  }, [hitokoto]);


  return (
    <div className="App" style={{
      height: "100vh",
      background: "#f0f2f5",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    }}>
      <Card className="timer-card card-content" style={{
        width: "80%",
        maxWidth: 600,
        backgroundColor: "#fff",
        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
        padding: "2rem",
        textAlign: "center",
      }}>
        <Row>
          <Col span={24}>
            {loading || !showHitokoto.hitokoto ? (
              <Spin size="large" style={{ margin: '0 auto' }} />
            ) : (
              <>
                <Typography.Title level={3}>“{showHitokoto.hitokoto}”</Typography.Title>
                {showHitokoto.from || showHitokoto.from_who? (
                  <Typography.Text type='secondary'>—— {showHitokoto.from} {showHitokoto.from_who}</Typography.Text>
                ) : (
                  <Typography.Text type='secondary'>—— {showHitokoto.creator}</Typography.Text>
                ) }
                <Space></Space>
              <Badge count={showHitokoto.type} color={showHitokoto.color} />
              </>
            )}
            <div>
            <Space>
            <Typography.Link href={`https://hitokoto.cn?uuid=${showHitokoto.uuid}`} target="_blank">查看详情</Typography.Link>
            <Typography.Link href='https://pyliubaolin.top/' target="_blank">站长主页</Typography.Link>
            </Space>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default App;